<template>
    <div class="moreNews">
        <!-- <div>
            <img src="@/assets/img/about1.png" alt="" style="width:100%;height:auto;">
        </div> -->
        <div class="moreNews_content">
            <!-- <div class="menuButon">
                <div class="item" v-for="(item,index) in caseType" :key="index" :class="queryParame.type==item.value?'active':''" @click="clickNewsBtn(item.value)">{{item.key}}</div>
                <div class="item" :class="queryParame.type==1?'active':''" @click="clickNewsBtn(1)">商业区</div>
                <div class="item" :class="queryParame.type==2?'active':''" @click="clickNewsBtn(2)">住宅</div>
                <div class="item" :class="queryParame.type==3?'active':''" @click="clickNewsBtn(3)">写字楼</div>
                <div class="item" :class="queryParame.type==4?'active':''" @click="clickNewsBtn(4)">医院</div>
                <div class="item" :class="queryParame.type==5?'active':''" @click="clickNewsBtn(5)">工业区</div>
                <div class="item" :class="queryParame.type==6?'active':''" @click="clickNewsBtn(6)">公园</div>
                <div class="item" :class="queryParame.type==7?'active':''" @click="clickNewsBtn(7)">各类球馆</div>
                <div class="item" :class="queryParame.type==8?'active':''" @click="clickNewsBtn(8)">旅游景点</div>
            </div> -->
            <div class="menuContent">
                <div class="cont_list" v-for="(item,index) in list" :key="index" >
                    <img :src="item.image" alt="">
                    <div class="name">
                        {{item.title}}
                    </div>
                </div>
            </div>
            <!-- <div class="pages">
                <div class="onePage pre" @click="preClick">
                    <img src="@/assets/img/pagePre.png" width="8" height="10" alt="" >
                </div>
                <div class="onePage" v-for="(item,index) in allIndex" :key="index" :class="queryParame.pageIndex==item?'active':''" @click="clickIndex(item)">{{item}}</div>
                <div class="onePage" :class="queryParame.pageIndex==2?'active':''" @click="clickIndex(2)">2</div>
                <div class="onePage" :class="queryParame.pageIndex==3?'active':''" @click="clickIndex(3)">3</div>
                <div class="onePage" :class="queryParame.pageIndex==4?'active':''" @click="clickIndex(4)">4</div>
                <div class="onePage" :class="queryParame.pageIndex==5?'active':''" @click="clickIndex(5)">5</div>
                <div class="onePage next" @click="nextClick">
                    <img src="@/assets/img/pageNext.png" width="8" height="10" alt="">
                </div>
                <div style="margin-left:20px;">
                    8条/页
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import selects from '@/api/selects.js'
import Case from '@/api/case.js'
export default {
    data(){
        return {
            caseType:[],
            queryParame:{
                type: 13,
                pageIndex: 1,
                pageSize: 8
            },
            list:[{image:require('@/assets/img/build1.png'), title:'中山志盛园区'},{image:require('@/assets/img/build2.png'), title:'中山坦南创客园'}
            ,{image:require('@/assets/img/build3.png'), title:'中澳世纪城'},{image:require('@/assets/img/build4.png'), title:'福州园区'}
            ,{image:require('@/assets/img/build5.png'), title:'中山医院'},{image:require('@/assets/img/build6.png'), title:'中山工业区'}
            ,{image:require('@/assets/img/build7.png'), title:'珠海横琴'},{image:require('@/assets/img/build8.png'), title:'西藏园区'}],
            total:0,
            allIndex:[],
            activeImg: require('@/assets/img/build1.png')
        }
    },
    created(){
        if(this.$route.query.type) {
            this.queryParame.type = parseInt(this.$route.query.type)
        }
    },
    mounted(){
        // this.initMenu()
        console.log(this.caseType,this.list,"shu")
    },
    methods: {
        initMenu(){
            selects.QueryParameterItem({code:'102'}).then(resp=>{
                if(resp.data.code == 200){
                    this.caseType = []
                    for(let item of resp.data.data.data){
                        this.caseType.push({
                            key: item.key,
                            value: parseInt(item.value) 
                        })
                    }
                    console.log(this.caseType,"类型2")
                    this.initCase()
                }
                
            })
        },
        initCase(){
            Case.Query(this.queryParame).then(resp=>{
                if(resp.data.code == 200){
                    this.list = resp.data.data.data
                    this.total = resp.data.data.total
                    console.log(this.list,"案例",)
                    console.log("总数",this.total,",每页8")
                    let xushu = this.total%this.queryParame.pageSize
                    let chushu = parseInt(this.total/this.queryParame.pageSize)
                    let allIndex = chushu
                    if(xushu>0){
                        allIndex+=1
                    }
                    this.allIndex = []
                    for(let i=1;i<allIndex+1;i++){
                        this.allIndex.push(i)
                    }
                    console.log("%",xushu)
                    console.log("/",chushu)
                    console.log("总页数",this.allIndex)
                }
            })
        },
        clickNewsBtn(e){
            console.log(e,"这话")
            this.queryParame.type = e
            this.queryParame.pageIndex = 1
            this.initCase()
            // this.activeImg = require(`@/assets/img/build${e}.png`)
        },
        clickIndex(e){
            this.queryParame.pageIndex = e
            this.initCase()
        },
        preClick(){
            if(this.queryParame.pageIndex>1){
                this.queryParame.pageIndex--
                this.initCase()
            }
        },
        nextClick(){
            if(this.queryParame.pageIndex<this.allIndex.length){
                this.queryParame.pageIndex++
                this.initCase()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.moreNews {
    &_content {
        // width: 1200px;
        width: 60%;
        // min-width: 1200px;
        margin: 0 auto;
        padding: 60px 0;
        .menuButon {
            display: flex;
            justify-content: space-between;
            .item {
                cursor: pointer;
                font-size: 24px;
                color: rgba(51, 51, 51, 1);
                margin-right: 20px;
                padding: 10px 0;
                &.active {
                    font-weight: bold;
                    border-bottom: 4px solid rgba(40, 123, 255, 1);
                }
            }
        }
        .menuContent {
            display: flex;
            justify-content: flex-start;
            padding: 30px 0;
            flex-wrap: wrap;
            .cont_list {
                width: 22%;
                position:relative;
                margin-right: 4%;
                margin-bottom: 4%;
                
                img {
                    width: 100%;
                    // height: auto;
                    height: 100%;
                    border-radius: 10px;
                }
                &:nth-child(4n+4) {
                    margin-right: 0;
                }
                .name {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                }
               
            }
        }
        .pages {
            margin: 40px 0;
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: flex-end;
            .onePage {
                cursor: pointer;
                margin-left: 20px;
                width: 27px;
                height: 27px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgba(0, 0, 0, 1);
                &.active {
                    background: #000;
                    color: white;
                }
            }
            .pre,.next {
                border-radius: 50%;
            }
        }
    }
}
</style>